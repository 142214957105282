import { useEffect } from "react";
import store from "../index";
import { memoizeOne } from "../utils/memoizeOne";
import { saveTerm } from "./helpers";

const memoizeSaveTerm = memoizeOne(saveTerm);

export default function Updater(): null {
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      memoizeSaveTerm(state.term.byId);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return null;
}
