import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { AxiosResponse } from "axios";

export const useRequest = <T = any>(title: string, fn: (...params: any[]) => Promise<AxiosResponse<T>>) => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const run = useCallback(
    async (...params: any[]): Promise<AxiosResponse<T>> => {
      setIsLoading(true);

      return fn(...params)
        .then((result) => {
          setIsLoading(false);
          return result;
        })
        .catch((error: any) => {
          setIsLoading(false);
          toast({
            title,
            position: "top-right",
            status: "error",
            description: error?.message,
            duration: 10000,
            isClosable: true,
          });
          throw error;
        });
    },
    [toast, fn, title]
  );

  return {
    run,
    isLoading,
  };
};
