import {
  Link,
  Checkbox,
  Input,
  Box,
  Text,
  Flex,
  styled,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { useToggle } from "hooks";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const BText = styled(Text, {
  baseStyle: {
    textAlign: "center",
  },
});

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .test("required", "enter your email address", (value) => {
      return !!value;
    }),
});

export const DEFAULT_TERMS_CHECK_DATA = {
  check1: false,
  check2: false,
  check3: false,
  email: "",
  emailValid: false,
};
export type TermsCheckData = { check1: boolean; check2: boolean; check3: boolean; email?: string; emailValid: boolean };

export const Terms: FC<{
  chain: string;
  onChange: (data: TermsCheckData) => void;
}> = ({ chain, onChange }) => {
  const [check1, setCheck1] = useToggle(false);
  const [check2, setCheck2] = useToggle(false);
  const [check3, setCheck3] = useToggle(false);

  const {
    watch,
    formState: { errors },
    register,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(schema),
    context: { agreeReceive: check3 },
    defaultValues: {
      email: "",
    },
  });
  const email = watch("email");
  const errorMsg3 = check3 ? (email.length > 0 ? errors.email?.message : "enter your email address") : null;

  const emailValid = (email.length > 0 ? !errors.email?.message : true) && !errorMsg3?.length;

  useEffect(() => {
    onChange({
      check1,
      check2,
      check3,
      email,
      emailValid,
    });
  }, [check1, check2, check3, email, emailValid]);

  return (
    <Box mt={[8]}>
      <BText fontSize={[24]} lineHeight={["30px"]} fontWeight={600}>
        Sign Terms & Conditions
      </BText>
      <BText mt={[3]} fontSize={[16]} lineHeight={1.5}>
        To claim rewards for participating in the {chain === "acala" ? "Acala" : "Karura"} Parachain Auction Campaign,
        you must read and accept the Parachain Campaign & Paradrop Programme Terms and Conditions.
      </BText>
      <Box>
        <Flex mt={[7]} align="flex-start">
          <Checkbox mt={"4px"} size="sm" onChange={setCheck1} />
          <Text fontSize={[16]} fontWeight={500} lineHeight={1} ml={[1]}>
            <Text as="span">I have read, fully understand and accept the</Text>{" "}
            <Text as="span" variant="gradient" textDecoration="underline" display="block">
              <Link href="https://acala.network/karura/terms" target="_blank">
                Terms & Conditions.
              </Link>
            </Text>
          </Text>
        </Flex>
        <Flex mt={[3]} align="center">
          <Checkbox size="sm" onChange={setCheck2} />
          <Text fontSize={[16]} fontWeight={500} lineHeight={1} ml={[1]}>
            <Text as="span">I have read and accept the </Text>
            <Text as="span" variant="gradient" textDecoration="underline">
              <Link href="https://acala.network/privacy" target="_blank">
                Privacy Policy.
              </Link>
            </Text>
          </Text>
        </Flex>
      </Box>

      <FormControl isInvalid={!emailValid}>
        <Text variant="label" mt={8}>
          Email address
        </Text>
        <Input autoComplete="off" {...register("email")} variant="primary" placeholder="Enter your email" />
        <FormErrorMessage>{errors.email?.message || errorMsg3}</FormErrorMessage>
        <FormHelperText>
          <Flex mt={[1]}>
            <Checkbox size="sm" onChange={setCheck3} />
            <Text fontSize={[12]} lineHeight={["18px"]} ml={[1]}>
              <Text as="span">I agree to receive email communications about Karura and Acala,</Text>
              <Text as="span">including exclusive launch updates and liquidity provider program.</Text>
            </Text>
          </Flex>
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
