import { Box, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { ClaimByExtensionProps } from "./types";
import { ClaimInfoResponse, fetchClaimInfo, fetchStatement, Statement } from "../../../../api";
import { useRequest } from "../../../../hooks";
import { useAppSelector } from "state";
import { selectedAccountSelector } from "state/account/selectors";
import { FixedPointNumber } from "@acala-network/sdk-core";
import { SelectAccount } from "components/SelectAccount";
import { ClaimRequest } from "./ClaimRequest";
import { useContext } from "react";
import { Context } from "./Context";

const DEFAULT_CLAIM_INFO: ClaimInfoResponse = { totalReward: "0", claimed: false, originClaimed: false, result: false };

export const SelectClaimAccount: FC<ClaimByExtensionProps> = () => {
  const { chain } = useContext(Context);
  const selectedAccount = useAppSelector(selectedAccountSelector);
  const { run, isLoading } = useRequest("Querying amount...", fetchClaimInfo);
  const [claimInfo, setClaimInfo] = useState<ClaimInfoResponse>(DEFAULT_CLAIM_INFO);
  const [statement, setStatement] = useState<Statement>();

  useEffect(() => {
    if (!selectedAccount?.address) return;

    setClaimInfo(DEFAULT_CLAIM_INFO);
    run(selectedAccount?.address, chain).then(({ data }) => {
      setClaimInfo(data);
    });
    fetchStatement(chain).then(({ data }) => {
      setStatement(data);
    });
  }, [run, selectedAccount, setClaimInfo, setStatement, chain]);

  const amt = FixedPointNumber.fromInner(claimInfo?.totalReward || "0", 12);
  const claimed = claimInfo.claimed || claimInfo.originClaimed;

  return (
    <Box w="100%">
      <Text variant="label">Account used to contribute in the crowdloan</Text>
      <SelectAccount isFullWidth />
      <ClaimRequest
        isLoading={isLoading}
        originClaimed={claimInfo.originClaimed}
        claimed={claimed}
        amount={amt}
        result={claimInfo?.result}
        statement={statement?.statement || ""}
      />
    </Box>
  );
};
