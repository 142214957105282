import { useToast } from "@chakra-ui/toast";
import { keyring } from "@polkadot/ui-keyring";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "..";
import { useApi } from "../../hooks/useApi";
import { updateAccount } from "./actions";
import { loadSelectedAddress, saveSelectedAddress } from "./helpers";
import { selectedAddressSelector } from "./selectors";

export default function Updater() {
  const { isApiReady, hasInjectedAccounts } = useApi();
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const [isInit, setIsInit] = useState(false);
  const toast = useToast();

  const hasInjectedAccountsRef = useRef(hasInjectedAccounts);

  hasInjectedAccountsRef.current = hasInjectedAccounts;

  useEffect(() => {
    if (isApiReady) {
      const list = keyring.getPairs() as any;

      if (!list.length && hasInjectedAccountsRef.current) {
        toast({
          status: "error",
          description:
            "Kusama address wasn’t found. Ensure you selected ‘Allow use on any chain’ in Polkadot.js extension",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        dispatch(updateAccount({ list, address: loadSelectedAddress() }));

        setIsInit(true);
      }
    }
  }, [isApiReady, dispatch, toast]);

  useEffect(() => {
    if (isInit) {
      saveSelectedAddress(selectedAddress);
    }
  }, [selectedAddress, isInit]);

  return null;
}
