import { Box, Text, Container, Link } from "@chakra-ui/react";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import bg from "../../../assest/claim/cover-bg.svg";

export const Cover: FC<{ chain: string }> = ({ chain }) => {
  const chainName = chain === "acala" ? "Acala" : "Karura";
  const chainToken = chain === "acala" ? "ACA" : "KAR";

  return (
    <Box backgroundImage={`url(${bg})`} backgroundSize="cover">
      <Container pt={[6, 110]} pb={[4, 12]} maxW={925}>
        <Text
          textAlign="center"
          color="white"
          mb={[2, 6]}
          fontSize={[36, 56]}
          fontWeight={700}
          lineHeight={1.28}
          textShadow="0px 1px 25px 0px #645AFF1A"
        >
          Check & Claim your tokens
        </Text>
        <Text
          mb={3}
          textAlign="center"
          color="white"
          display={["none", "block"]}
          fontSize={[16, 20]}
          lineHeight={1.5}
          fontWeight={500}
          textShadow="0px 4px 24px 0px #E6007A33"
        >
          If you participated in {chainName} crowdloan from channels other than the officially supported ones, you will
          need to read and agree to our Terms and Conditions to claim your tokens. You can check if you need to claim{" "}
          {chainToken}{" "}
          <Text as="span" textDecoration="underline">
            <NavLink to="/">here</NavLink>
          </Text>
          .
        </Text>
        <Text
          textAlign="center"
          fontSize={[16, 20]}
          lineHeight={1.5}
          fontWeight={500}
          color="grey.6"
          display={["none", "block"]}
          textShadow="0px 4px 24px 0px #E6007A33"
        >
          If you have any questions about ACA and/or KAR, please contact us in our{" "}
          <Link target="_blank" href="https://discord.gg/7qfb9jTjyd" textDecoration="underline">
            Discord support channel
          </Link>
          .
        </Text>
      </Container>
    </Box>
  );
};
