import { FixedPointNumber } from "@acala-network/sdk-core";
import {
  FormErrorMessage,
  Box,
  Text,
  Flex,
  Button,
  ListItem,
  UnorderedList,
  FormControl,
  Input,
  useToast,
  Link,
} from "@chakra-ui/react";
import { FC, useCallback, useContext, useState } from "react";
import { DEFAULT_TERMS_CHECK_DATA, Terms, TermsCheckData } from "../Terms";
import { Context } from "./ClaimManuallyContext";
import { format } from "d3-format";
import { ClaimSuccess, OriginClaimedSuccess, NoNeedClaim } from "./ClaimSuccess";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRequest } from "hooks";
import { postClaim } from "api";
import { isEmpty } from "lodash";
import { ReactComponent as CopyIcon } from "../../../../assest/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

const fromatNumber = format(",.2f");

const ClaimAmount: FC<{ amount: string; claimed: boolean; originClaimed: boolean }> = ({
  amount,
  claimed,
  originClaimed,
}) => {
  const { chain } = useContext(Context);
  const chainToken = chain === "acala" ? "ACA" : "KAR";

  return (
    <Flex
      bg="grey.1"
      borderRadius={6}
      padding={["16px 24px"]}
      h={62}
      mt={"36px"}
      align="center"
      justify="space-between"
      color="white"
    >
      <Flex align="center">
        <Text as="span" display="inline-block" fontSize={["24px", "30px"]} fontWeight={700} mr={"4px"}>
          {fromatNumber(FixedPointNumber.fromInner(amount, 12).toNumber())}
        </Text>
        <Text as="span" display="inline-block" fontSize={"20px"} fontWeight={500}>
          {chainToken}
        </Text>
      </Flex>
      <Box
        display={["none", "block"]}
        h={["28px"]}
        borderRadius="18px"
        border="1px solid"
        borderColor="origin.1"
        bg="origin.1.10%"
        padding={["0 14px"]}
      >
        <Text color="origin.1" fontSize={12} lineHeight={"26px"} fontWeight={500}>
          {originClaimed ? "Distributed" : claimed ? "Scheduled for distribution" : "TO BE CLAIMED"}
        </Text>
      </Box>
    </Flex>
  );
};

const scheme = Yup.object().shape({
  eventTx: Yup.string()
    .required("signature hash OR remark transaction is required")
    .test("checkHashError", "invalid input", (value) => {
      if (!value) return false;

      if (value.length !== 66 && value.length !== 130) return false;

      return true;
    }),
});

export const ClaimConfirm: FC = () => {
  const { setStep, claimInfo, statement } = useContext(Context);
  const [showSubmit, setShowSubmit] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const { chain } = useContext(Context);

  const handleCancel = useCallback(() => {
    setStep(0);
  }, [setStep]);

  const [{ check1, check2, check3, email, emailValid }, setChecked] =
    useState<TermsCheckData>(DEFAULT_TERMS_CHECK_DATA);

  const {
    watch,
    formState: { errors },
    register,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(scheme),
  });

  const eventTx = watch("eventTx");

  const toast = useToast();

  const handleCheckTerm = useCallback(async () => {
    if (!check1 || !check2) return;

    if (check3 && !email) return;

    setShowSubmit(true);
  }, [check1, check2, check3, email]);

  const { run, isLoading } = useRequest("Submit", postClaim);

  const handleCopy = useCallback(() => {
    toast({
      status: "success",
      description: "copy statement success",
      position: "top-right",
      duration: 3000,
    });
  }, [toast]);

  const handleSubmit = useCallback(async () => {
    if (!isEmpty(errors)) return;

    if (!eventTx) return;

    if (!check1 || !check2) return;

    if (check3 && !email) return;

    run(
      {
        address: claimInfo.address,
        email: emailValid ? email : "",
        receiveEmail: check3,
        eventTx,
      },
      chain
    )
      .then(({ data }) => {
        setSubmitSuccess(true);
        toast({
          title: "Sign Terms",
          status: "success",
          position: "top-right",
        });
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check1, check2, check3, emailValid, email, eventTx, chain]);

  return (
    <Box>
      <Flex justify="flex-end">
        <Button
          variant="link"
          color="white"
          width="64px"
          height="32px"
          transform={["translate3d(0, 0, 0)", "translate3d(134px, 0, 0)"]}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Flex>
      <Box mt={[6]}>
        <Text variant="label">Participating Address</Text>
        <Text
          h={"58px"}
          bg="grey.2"
          borderRadius="6px"
          lineHeight="58px"
          pl={"22px"}
          letterSpacing={"1.6px"}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          w="100%"
          overflow="hidden"
        >
          {claimInfo.address}
        </Text>
      </Box>
      {claimInfo?.result && claimInfo?.totalReward !== "0" ? (
        <>
          <ClaimAmount
            amount={claimInfo.totalReward}
            claimed={claimInfo.claimed}
            originClaimed={claimInfo.originClaimed}
          />
          {submitSuccess ? (
            <ClaimSuccess />
          ) : claimInfo.originClaimed ? (
            <OriginClaimedSuccess />
          ) : claimInfo.claimed ? (
            <ClaimSuccess />
          ) : !showSubmit ? (
            <Box>
              <Terms onChange={(data) => setChecked(data)} chain={chain} />
              <Button
                w="100%"
                h={58}
                mt={["75px"]}
                fontSize={"20px"}
                lineHeight="24px"
                fontWeight={500}
                variant="primary-gradient"
                onClick={handleCheckTerm}
              >
                Generate data to sign manually
              </Button>
            </Box>
          ) : (
            <Box>
              <Box mt={[4, 8]}>
                <Text fontSize={["18px", "24px"]} fontWeight={600} lineHeight={1.5} textAlign="center">
                  <Text as="span">Sign the data using</Text>{" "}
                  <Text variant="gradient" as="span">
                    one of the
                  </Text>{" "}
                </Text>
                <Text fontSize={["18px", "24px"]} fontWeight={600} lineHeight={1.5} textAlign="center">
                  <Text as="span">following methods:</Text>
                </Text>
                <UnorderedList mt={["25px"]} color="white">
                  <ListItem fontSize={["12px", "16px"]}>
                    <Text>
                      <Text as="span">Sign the message (</Text>
                      <Text as="span" variant="gradient">
                        <Link
                          target="_blank"
                          href={
                            chain === "acala"
                              ? "https://wiki.acala.network/acala/acala-crowdloan/claim-aca#option-1-using-polkadot-js-browser-extension"
                              : "https://wiki.acala.network/karura/crowdloan/claim-kar#using-sign-and-verify"
                          }
                        >
                          guide here
                        </Link>
                      </Text>
                      <Text as="span">)</Text>
                    </Text>
                  </ListItem>
                  <ListItem fontSize={["12px", "16px"]}>
                    <Text>
                      <Text as="span">Use system.remarkWithEvent (</Text>
                      <Text as="span" variant="gradient">
                        <Link
                          target="_blank"
                          href={
                            chain === "acala"
                              ? "https://wiki.acala.network/acala/acala-crowdloan/claim-aca#option-2-using-system-remark"
                              : "https://wiki.acala.network/karura/crowdloan/claim-kar#using-system-remark"
                          }
                        >
                          guide here
                        </Link>
                      </Text>
                      <Text as="span">)</Text>
                    </Text>
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box mt={[4]} border="1px solid #4f4f4f" borderRadius={"6px"} padding="24px 36px">
                <Text>{statement?.statement}</Text>
                <Flex justify="flex-end" mt={3}>
                  <CopyToClipboard text={statement?.statement || ""} onCopy={handleCopy}>
                    <Flex align="center" cursor="pointer">
                      <CopyIcon />
                      <Text variant="gradient" ml={1}>
                        Copy
                      </Text>
                    </Flex>
                  </CopyToClipboard>
                </Flex>
              </Box>
              <Box mt={"44px"}>
                <FormControl isInvalid={errors.eventTx?.message}>
                  <Text variant="label">Paste the signature hash OR remark transaction hash here:</Text>
                  <Input variant="primary" autoComplete="off" {...register("eventTx")} />
                  <FormErrorMessage>{errors.eventTx?.message}</FormErrorMessage>
                  <Button
                    w="100%"
                    h={58}
                    mt={["75px"]}
                    fontSize={"20px"}
                    lineHeight="24px"
                    fontWeight={500}
                    variant="primary-gradient"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Submit and claim
                  </Button>
                </FormControl>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <NoNeedClaim />
      )}
    </Box>
  );
};
