import styled from "styled-components";

const ListRoot = styled.div`
  background: var(--table-bg);
  padding: 28px 0;
  margin: 0 60px;
  border-bottom: 1px solid var(--table-border);

  @media (max-width: 640px) {
    padding: 14px 0;
    margin: 0 30px;
  }

  &.noBorder {
    border-bottom: none;
  }
`;

export const ListCell = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  color: var(--text-primary-color);

  &:first-child {
    margin-left: 48px;
    color: var(--text-second-color);

    @media (max-width: 640px) {
      margin-left: 0px;
      margin-bottom: 8px;
    }
  }

  &:last-child {
    font-weight: 600;
  }

  &:first-child:before {
    content: '';

    position: absolute;

    width: 2px;
    height: 2px;
    border-radius: 2px;
    top: calc(50% - 1px);
    left: -8px;
    background: var(--text-second-color);
  }
`;

export const ListTitle = styled.div`
  margin-left: 40px;

  @media (max-width: 640px) {
    margin-left: -16px;
  }

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--text-primary-color);
`;

export const ListItem = styled.div`
  margin-bottom: 20px; 
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr, 2);
    margin-left: 8px;
  }

  &.title {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &.title:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListBody = styled.div``;

export const List = ListRoot as typeof ListRoot & {
  Cell: typeof ListCell;
  Body: typeof ListBody;
  Item: typeof ListItem;
  Title: typeof ListTitle;
};

List.Title = ListTitle;
List.Body = ListBody;
List.Cell = ListCell;
List.Item = ListItem;