import { createReducer } from '@reduxjs/toolkit';
import { DistributionData } from './types';
import { setQueryLoading, setQueryResult } from './actions';

const initState: DistributionData = {
	isLoading: false
}

export default createReducer(initState, (builder) => 
	builder.addCase(setQueryResult, (state, action) => {
		state.result = action.payload.data;
	}).addCase(setQueryLoading, (state, action) => {
		state.isLoading = action.payload;
	})
);