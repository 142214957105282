import styled from "styled-components";

const TableRoot = styled.div`
  --table-border: #f2f2f2;
  --table-bg: #f8f8f8;
  --table-header: #e0e0e0;
  --table-font-color: #4f4f4f;
  --table-cell-border: #4f4f4f;

  box-shadow: 0px 4px 20px rgba(230, 0, 122, 0.02);
  border-radius: 12px;
  border: 1px solid var(--table-border);
  background: var(--table-bg);
`;

export const TableCell = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--table-font-color);
  padding: 26px 0;
  border-bottom: 1px solid var(--table-cell-border);
`;

export const TableHeader = styled.div`
  display: flex;
  border-radius: 12px 12px 0px 0px;
  padding: 0 60px;
  background: var(--table-header);

  @media (max-width: 640px) {
    flex-direction: column;
    padding: 0 16px;
    border-bottom: none;
  }

  & ${TableCell} {
    height: 44px;
    padding: 0;
    border-bottom: none;
  }
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 60px;

  @media (max-width: 640px) {
    padding: 0 16px;
    grid-template-columns: repeat(1, 1fr);
    border-bottom: none;
  }

  &:last-child ${TableCell} {
    border-bottom: none;
  }
`;

const TableBody = styled.div``;

export const Table = TableRoot as typeof TableRoot & {
  Header: typeof TableHeader;
  Cell: typeof TableCell;
  Body: typeof TableBody;
  Row: typeof TableRow;
};

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Row = TableRow;
