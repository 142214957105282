import { Box, Link, Button, Text } from "@chakra-ui/react";
import { useApi } from "hooks";
import { FC } from "react";
import { useCallback, useContext } from "react";
import { Context, Provider } from "./Context";
import { SelectClaimAccount } from "./SelectClaimAccount";

export const UseExtension = () => {
  const { setStep } = useContext(Context);
  const { loadExtension, isExtensionLoading } = useApi();

  const handleClick = useCallback(() => {
    loadExtension().then(() => {
      setStep(1);
    });
  }, [loadExtension, setStep]);

  return (
    <Box>
      <Box>
        <Text display={["none", "block"]} fontSize={[14]} lineHeight={1.5}>
          <Link
            textDecoration="underline"
            color="origin.karura"
            href="https://polkadot.js.org/extension/"
            target="_blank"
          >
            Get Polkadot.js extension
          </Link>
        </Text>
        <Box mt={[3]}>
          <Text variant="label">Account used to contribute in the crowdloan</Text>
          <Button variant="primary-gradient" h={["56px"]} w="100%" isLoading={isExtensionLoading} onClick={handleClick}>
            Use Polkadot.js Extension
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const Content = () => {
  const { step } = useContext(Context);

  return (
    <>
      {step === 0 ? <UseExtension /> : null}
      {step === 1 ? <SelectClaimAccount hasExtension={true} isExtensionLoading={false} /> : null}
    </>
  );
};

interface ClaimByExtensionProps {
  chain: string;
}

export const ClaimByExtension: FC<ClaimByExtensionProps> = ({ chain }) => {
  return (
    <Provider chain={chain}>
      <Content />
    </Provider>
  );
};
