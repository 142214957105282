import React, { FC, PropsWithChildren, useMemo, useState } from "react";

export interface ClaimByExtensionData {
  step: number;
  setStep: (setp: number) => void;
  chain: string;
}

export const Context = React.createContext<ClaimByExtensionData>({} as ClaimByExtensionData);

export const Provider: FC<PropsWithChildren<{ chain: string }>> = ({ chain, children }) => {
  const [step, setStep] = useState<number>(0);

  const value = useMemo(() => {
    return {
      step,
      setStep,
      chain,
    } as ClaimByExtensionData;
  }, [step, setStep, chain]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
