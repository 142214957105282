import { extendTheme } from "@chakra-ui/react";

const createSpace = (start: number, end: number, step: number, gen: (index: number) => string) => {
  const result = [];

  for (let i = start; i <= end; i += step) {
    result.push([i, gen(i)]);
  }

  return Object.fromEntries(result);
};

export const customTheme = extendTheme({
  fonts: {
    body: `"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  },
  space: createSpace(0, 12, 1, (i) => `${i * 8}px`),
  colors: {
    black: "#1B1B1B",
    red: {
      100: "rgba(238, 35, 80, 1)",
    },
    origin: {
      1: "rgba(235, 110, 89, 1)",
      "1-10%": "rgba(235, 110, 89, 0.1)",
      karura: "#FF4C3B",
    },
    grey: {
      1: "#333333",
      2: "#4f4f4f",
      3: "#828282",
      4: "#BDBDBD",
      6: "#f2f2f2",
    },
    blue: {
      1: "#5A81FF",
    },
  },
  components: {
    Input: {
      variants: {
        primary: {
          field: {
            height: "56px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: "blue.1",
            background: "grey.2",
            color: "white",
            fontSize: "16px",
            lineHeight: 1.5,
            letterSpacing: "1.6px",

            _placeholder: {
              fontSize: "16px",
              lineHeight: 1.5,
              letterSpacing: "1.6px",
            },
          },
        },
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 400,
        color: "white",
        lineHeight: 1.5,
        textDecorationColor: "origin.karura",
      },
      variants: {
        gradient: {
          bg: "linear-gradient(319.48deg, #E40C5B 0, #FF4C3B 100%)",
          bgClip: "text",
        },
        label: {
          mb: 1,
          fontSize: [14, 16],
          lineHeight: 1.5,
          color: "grey.4",
          letterSpacing: "1.6px",
          fontWeight: 500,
        },
      },
    },
    Button: {
      baseStyle: {
        transition: "filter .2s ease",
        _hover: {
          filter: "brightness(1.2)",
        },
        _focus: {
          boxShadow: "none",
        },
      },
      variants: {
        "primary-gradient": {
          color: "white",
          bg: "linear-gradient(61.97deg, #FF4C3B 0%, #E40C5B 100%)",
          _loading: {
            _hover: {
              bg: "linear-gradient(61.97deg, #FF4C3B 0%, #E40C5B 100%)",
            },
          },
        },
      },
    },
    Popover: {
      parts: ["popper", "content"],
      baseStyle: {
        popper: {
          border: "none",
          width: "fit-content",
          maxWidth: "fit-content",
        },
        content: {
          _focus: {
            outline: 0,
            boxShadow: "none",
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        outline: "none",
        control: {
          _focus: {
            boxShadow: "none",
          },
          _checked: {
            background: "linear-gradient(332.71deg, #E40C5B -23.23%, #FF4C3B 124.74%)",
            borderColor: "transparent",

            _hover: {
              background: "linear-gradient(332.71deg, #E40C5B -23.23%, #FF4C3B 124.74%)",
              borderColor: "transparent",
            },
          },
        },
      },
      sizes: {
        sm: {
          control: {
            w: "24px",
            h: "24px",
          },
          icon: {
            fontSize: "12px",
          },
        },
      },
    },
  },
});
