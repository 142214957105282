import { ChakraProvider } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import { customTheme } from "./theme-config";

export const ThemeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <ChakraProvider resetCSS theme={customTheme}>
      {children}
    </ChakraProvider>
  );
};
