import { Box, Button, Flex, Popover, PopoverContent, PopoverTrigger, Stack } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../state";
import { updateAccount } from "../state/account/actions";
import { accountListSelector, selectedAccountSelector } from "../state/account/selectors";
import { Address } from "../components/Address";

type SelectAccountProps = {
  withLabel?: boolean;
  isFullWidth?: boolean;
};

export const SelectAccount: React.FC<SelectAccountProps> = ({ withLabel = false, isFullWidth = false }) => {
  const dispatch = useAppDispatch();

  const accountList = useAppSelector(accountListSelector);
  const selectedAccount = useAppSelector(selectedAccountSelector);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = useCallback(() => {
    const index = accountList?.findIndex((account) => account.address === selectedAccount?.address);
    setActiveIndex(index || 0);
    setIsOpen(true);
  }, [accountList, selectedAccount]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSelect = useCallback((index: number) => {
    setActiveIndex(index);
    dispatch(updateAccount({ address: accountList[index]?.address }));
    setIsOpen(false);
  }, [accountList, dispatch]);

  return (
    <Box position="relative">
      <Popover placement="bottom-end" gutter={4} isOpen={isOpen} closeOnBlur={false} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            minWidth={withLabel ? "242px" : "224px"}
            width={isFullWidth ? "100%" : "auto"}
            borderRadius="6px"
            variant="primary-gradient"
            padding="16px 24px"
            height={withLabel ? "60px" : "56px"}
            sx={
              isFullWidth
                ? {
                    "& + div": {
                      width: "100%",
                      maxWidth: "100%",
                    },
                  }
                : undefined
            }
          >
            <Stack spacing="2px" width="100%">
              {withLabel ? (
                <Box fontSize="xs" textAlign="left">
                  use wallet
                </Box>
              ) : null}
              <Flex justifyContent="space-between" alignItems="center" direction="row">
                <Address
                  textAlign="left"
                  address={selectedAccount?.address || null}
                  name={(selectedAccount as any)?.meta.name}
                  view="full"
                />
                <Box>
                  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16 2.80855L14.1115 0.898712L8 7.07937L1.88848 0.898712L0 2.80855L8.00033 10.8987L16 2.80855Z"
                      fill="currentColor"
                    />
                  </svg>
                </Box>
              </Flex>
            </Stack>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          background="#333333"
          opacity="0.9"
          border="1px"
          color="white"
          borderColor="#4F4F4F"
          boxShadow="0px 4px 24px rgba(230, 0, 122, 0.2)"
          borderRadius="8px"
          maxHeight={400}
          overflowY="auto"
        >
          <Box px="32px" py="24px">
            <Stack spacing="24px" pl="16px">
              {(accountList || []).map((account, index) => {
                const isActive = activeIndex === index;
                return (
                  <Flex
                    key={index}
                    alignItems="center"
                    cursor="pointer"
                    position="relative"
                    onClick={() => onSelect(index)}
                  >
                    {isActive ? (
                      <Box position="absolute" left="-32px" top="10px">
                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.956 2L5.73624 10.5556L2 6.66667"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                    ) : null}
                    <Address
                      addressColor={isActive ? "white" : "#828282"}
                      view="full"
                      name={account.meta.name}
                      address={account.address}
                    />
                  </Flex>
                );
              })}
            </Stack>
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
