import { Container, Box, Text, Flex } from "@chakra-ui/react";
import { FC, PropsWithChildren, ReactElement, useState } from "react";
import { ClaimByExtension } from "./claim-by-extension";
import { ClaimByManually } from "./claim-by-manually";

const SelectorItem: FC<{ active: boolean; onClick: () => void; children: ReactElement }> = ({
  active,
  children,
  onClick,
}) => {
  return (
    <Flex
      onClick={onClick}
      w={334}
      align="center"
      justify="center"
      color={active ? "origin.karura" : "grey.3"}
      fontSize={[14, 24]}
      whiteSpace="nowrap"
      lineHeight={["24px", "29px"]}
      fontWeight={500}
      bg="black"
      borderColor={active ? "origin.karura" : "grey.2"}
      borderWidth={"1px"}
      borderRadius={"8px"}
      pt={18}
      pb={18}
      cursor="pointer"
      zIndex={active ? 1 : 0}
      _last={{ ml: "-12px" }}
      transitionProperty="all"
    >
      {children}
    </Flex>
  );
};

const Selector: FC<{ active: number; onChange: (index: number) => void; chain: string }> = ({
  active,
  chain,
  onChange,
}) => {
  return (
    <Flex align="center" justify="center">
      <SelectorItem active={active === 0} onClick={() => onChange(0)}>
        <Text userSelect="none">Polkadot Extension</Text>
      </SelectorItem>
      <SelectorItem active={active === 1} onClick={() => onChange(1)}>
        <Text userSelect="none">Claim Manually</Text>
      </SelectorItem>
    </Flex>
  );
};

const MainContent: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box
      mt={[4, 8]}
      borderRadius={4}
      pt={[2, 8]}
      pl={[2, "160px"]}
      pb={[4, 8]}
      pr={[2, "160px"]}
      border="2px solid"
      borderColor="grey.1"
      overflowY="hidden"
      minH={600}
    >
      {children}
    </Box>
  );
};

interface MainProps {
  chain: string;
}

export const Main: FC<MainProps> = ({ chain }) => {
  const [active, setActive] = useState<number>(0);

  return (
    <Box bg="black">
      <Container maxW="965" pt={[8, 12]} mb={[2, 8]}>
        <Selector active={active} onChange={setActive} chain={chain} />
        <MainContent>
          {active === 0 ? <ClaimByExtension chain={chain} /> : null}
          {active === 1 ? <ClaimByManually chain={chain} /> : null}
        </MainContent>
      </Container>
    </Box>
  );
};
