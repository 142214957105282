import axios from 'axios';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'state';
// import { uniteAddress } from 'utils';
import { setQueryLoading, setQueryResult } from './actions';
import { get } from 'lodash';

export const useIsQuerying = () => {
	return useSelector<AppState>((value) => value.distribution.isLoading)
}

export const useQueryResult = () => {
	return useSelector<AppState, AppState["distribution"]["result"]>((value) => value.distribution.result);
}

export const useSetQueryResult = () => {
	const dispatch = useDispatch();

	return useCallback((data: any) => {
		dispatch(setQueryResult(data));
	}, [dispatch]);
}

export const useSetQueryLoading = () => {
	const dispatch = useDispatch();

	return useCallback((loading: boolean) => {
		dispatch(setQueryLoading(loading));
	}, [dispatch]);
}

export const getDistributionData = async (address: string) => {
	// const acalaHost = 'https://api.polkawallet.io/acala-distribution';
	const acalaHost = 'https://api.polkawallet.io/acala-distribution-v2';

	const data: Array<any> = await axios.all([
		axios.get(`${acalaHost}/airdrop?account=${address}`),
		axios.get(`${acalaHost}/nft?account=${address}`),
		axios.get(`${acalaHost}/build-acala?account=${address}`),
		axios.get(`${acalaHost}/crowdloan?account=${address}`)
	]);

	const acala = {
		airdrop: get(data, '0.data.data.aca', []),
		buildAcala: get(data, '2.data.data.build1.aca', 0),
		buildAcala2: get(data, '2.data.data.build2', 0),
		crowdloans: get(data, '3.data.data.acala', [])
	};

	const karura = {
		airdrop: get(data, '0.data.data.kar', []),
		buildAcala: get(data, '2.data.data.build1.kar', 0),
		buildAcala2: { community: 0, public: 0 },
		nft: get(data, '1.data.data', []).map((item: any) => {
			return {
				...item,
				imageSrc: `https://ipfs.io/${item.tokenInfo.image.replace('ipfs://', '')}`
			}
		}),
		crowdloans: get(data, '3.data.data.karura', [])
	};

	return { acala, karura };
};

export const useQueryData = () => {
	const dispatch = useDispatch();

	return useCallback((address: string) => {
		dispatch(setQueryLoading(true));

		return getDistributionData(address).then((data) => {
			dispatch(setQueryResult({ data }));
		}).finally(() => {
			dispatch(setQueryLoading(false));
		})
	}, [dispatch]);
}