import { Page, PageHeader } from "../../components";
import { FC, useMemo } from "react";
import { Cover, Main } from "./components";
import { Api } from "api-provider/Api";
import Updater from "state/account/updater";
import TermUpdater from "state/term/updater";
import { useParams } from "react-router";

export const Claim: FC = () => {
  const params = useParams();
  // get the chain config form url, use `karura` as default
  const chain = params?.chain || "karura";

  const endpoints = useMemo(() => {
    const array: string[] =
      chain === "acala"
        ? ["wss://polkadot.api.onfinality.io/public-ws"]
        : ["wss://kusama-rpc.polkadot.io", "wss://kusama.api.onfinality.io/public-ws", "wss://kusama.elara.patract.io"];
    return array
      .map((a) => [Math.random(), a])
      .sort((a, b) => (a[0] as number) - (b[0] as number))
      .map((a) => a[1]) as string[];
  }, [chain]);

  return (
    <Api endpoints={endpoints}>
      <Page bg="black">
        <PageHeader />
        <Cover chain={chain} />
        <Main chain={chain} />
      </Page>
      <Updater />
      <TermUpdater />
    </Api>
  );
};
