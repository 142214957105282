import { Box } from '@chakra-ui/react';
import BaseIdentityIcon from '@polkadot/react-identicon';
import React from 'react';

type IdentityIconProps = {
    value: string;
    size?: number;
} & React.ComponentProps<typeof Box>;

export const IdentityIcon: React.FC<IdentityIconProps> = ({
    value,
    size = 24,
}) => {
    return (
        <Box
            sx={{
                transform: 'translateY(3px)',
                display: 'inline-block',
            }}
        >
            <BaseIdentityIcon value={value} size={size} theme={'polkadot'} />
        </Box>
    );
};
