import { Input, Flex, Box, Text, Container, Tooltip, createIcon } from "@chakra-ui/react";
import styled from "@emotion/styled";
import bgSrc from "assest/query-bg.svg";
import { ReactComponent as SearchIcon } from "assest/search.svg";
import { ReactComponent as SelectedIcon } from "assest/checked.svg";
import { ReactComponent as InfoIcon } from "assest/info.svg";
import { FC, useCallback, useState } from "react";
import { isValidAddress } from "utils";
import { useIsQuerying, useQueryData, useQueryResult } from "state/distribution/hooks";

const Title = styled.div``;

const Wrapper = styled.div``;
const InputBox = styled.div``;
const QueryConfigRoot = styled.div``;

const QueryConfig = [
  {
    title: "Mandala Festival",
    tooltip: "Available to check.",
    enable: true,
  },
  {
    title: "Build Acala #1",
    tooltip: "Available to check.",
    enable: true,
  },
  {
    title: "Build Acala #2",
    tooltip: "Available to check.",
    enable: true,
  },
  {
    title: "Karura Crowdloan",
    tooltip: "Available to check.",
    enable: true,
  },
  {
    title: "Acala Crowdloan",
    tooltip: "Available to check.",
    enable: true,
  },
  {
    title: "Other events",
    tooltip: "Coming soon.",
    enable: false,
  },
];

const QueryConfigItem: FC<typeof QueryConfig[number] & { className?: string }> = ({
  className,
  title,
  tooltip,
  enable,
}) => {
  return (
    <Flex
      className={`${className} ${enable ? "" : "disabled"}`}
      align="center"
      justify="center"
      padding={["8px 12px", "16px 24px"]}
      border="1px solid #a2e070"
      borderRadius={25}
      cursor="pointer"
      color={enable ? "#4f4f4f" : "#bdbdbd"}
      bg="#f2f2f2"
      borderColor={enable ? "#a2e070" : "#e0e0e0"}
      sx={{ columnGap: 8 }}
    >
      {title}
      <Tooltip
        label={tooltip}
        hasArrow
        fontSize={14}
        fontWeight={500}
        color="#828282"
        bg="#C4C4C4"
        borderRadius={12}
        p={"10px"}
      >
        {enable ? <SelectedIcon /> : <InfoIcon />}
      </Tooltip>
    </Flex>
  );
};

const SearchBtn: FC<{ className?: string; onClick: () => void }> = ({ className, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        "@media (max-width: 640px)": {
          flex: "0 0 44px",

          svg: {
            transform: "scale(0.5)",
          },
        },
        margin: "-1px",
        height: "calc(100% + 2px)",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "0 8px 8px 0",
        flex: "0 0 88px",
        cursor: "pointer",
        overflow: "hidden",

        "& > svg": {
          zIndex: 1,
          pointerEvents: "none",
        },
      }}
    >
      <Box
        _hover={{ opacity: 1 }}
        opacity="0.7"
        transition="opacity .4s"
        position="absolute"
        zIndex={0}
        top={0}
        right={0}
        bottom={0}
        left={0}
        bg="linear-gradient(328.08deg, rgba(100, 90, 255, 1) -40.69%, rgba(228, 12, 91, 1) 36.17%, rgba(255, 76, 59, 1) 79.87%)"
      />
      <SearchIcon />
    </Box>
  );
};

const SearchInput: FC<{
  className?: string;
  error: string;
  value: string;
  onChange: (e: any) => void;
  handleQuery: () => void;
}> = ({ className, error, value, onChange, handleQuery }) => {
  const [focus, setFocus] = useState<boolean>(false);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, [setFocus]);

  return (
    <Flex
      mt={[42, 84]}
      h={[42, 84]}
      borderRadius={8}
      fontSize={[16, 20]}
      lineHeight={1.5}
      bg="white"
      align="stretch"
      boxShadow="0 1px 25px rgba(100, 90, 255, 0.1)"
      border="1px solid transparent"
      borderColor={error ? "rgba(228, 12, 91, 1)" : focus ? "#645aff" : "transparent"}
      transition="all .2s"
      className={className}
    >
      <Input
        fontSize="inherit"
        h="100%"
        p={["0 8px", "24px 32px"]}
        _focus={{ border: "none" }}
        border="none"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        color={error ? "rgba(228, 12, 91, 1)" : "#000"}
        placeholder="Enter your account address..."
        _placeholder={{
          fontSize: "inherit",
          lineHeight: 1.5,
        }}
      />
      <SearchBtn onClick={handleQuery} />
    </Flex>
  );
};

export const QueryCard = styled(() => {
  const isLoading = useIsQuerying();
  const queryData = useQueryData();
  const data = useQueryResult();

  const [input, setInput] = useState<string>("");
  const [error, setError] = useState<string>("");

  const validator = useCallback(
    (value: string) => {
      if (!value) {
        setError("");

        return;
      }

      if (!isValidAddress(value)) {
        setError("The address is invalid...");

        return false;
      }

      // clear error
      setError("");

      return true;
    },
    [setError]
  );

  const onChange = useCallback(
    (e) => {
      const value = e.target.value;

      setInput(value);
      validator(value);
    },
    [validator]
  );

  const scrollToResult = useCallback(() => {
    const $ele = document.querySelector("#result");

    $ele?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, []);

  const handleQuery = useCallback(() => {
    if (isLoading) return;

    if (!input) {
      setError("Please input address.");

      return;
    }

    if (!validator(input)) {
      return;
    }

    queryData(input).then(() => {
      // scroll to result
      scrollToResult();
    });
  }, [isLoading, validator, input, setError, queryData, scrollToResult]);

  return (
    <Box w="100%" h="100vh" bg={`url(${bgSrc})`} bgSize="cover" id="search">
      <Container pt={120} maxW={925}>
        <Text textAlign="center" color="grey.1" mb={6} fontSize={[26, 36]} fontWeight="bold" lineHeight="30px">
          Check your ACA & KAR
        </Text>
        <SearchInput value={input} error={error} onChange={onChange} handleQuery={handleQuery} />
        <Text mt={[5, 10]} fontSize={[16, 20]} fontWeight={500} lineHeight={1} color="#4f4f4f">
          <Text as="span" color="#828282" mr="4px" display="inline-block">
            Status:
          </Text>
          {error
            ? error
            : isLoading
            ? "We are loading your details ..."
            : data
            ? "Check your details"
            : "Enter your address to see the details..."}
        </Text>
        <Text
          m={["10px 0 20px 0", "20px 0 27px 0"]}
          fontWeight={500}
          fontSize={[14, 16]}
          lineHeight={1.5}
          textAlign="right"
          color="#828282"
        >
          Last updated：17 Dec 2021
        </Text>
        <Flex mt={[15, 33]} wrap="wrap" sx={{ rowGap: "20px", columnGap: "24px" }}>
          {QueryConfig.map((item) => (
            <QueryConfigItem {...item} key={item.title} />
          ))}
        </Flex>
      </Container>
    </Box>
  );
})`
  width: 100%;
  height: 100vh;
  padding-top: 120px;
  background-image: url(${bgSrc});
  background-size: cover;
  background-repeat: no-repeat;

  ${Title} {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;
    color: #333333;
  }

  ${Wrapper} {
    max-width: 920px;
    margin: 0 auto;
  }

  ${QueryConfigRoot} {
    margin-top: 33px;
    flex-wrap: wrap;
    display: flex;
    row-gap: 20px;
    column-gap: 24px;
  }
`;
