import { Page, PageHeader } from "../../components";
import { FC } from "react";
import { Cover } from "./components/Cover";
import { QueryCard } from "./components/QueryCard";
import { Result } from "./components/Result";

export const Home: FC = () => {
  return (
    <Page>
      <PageHeader />
      <Cover />
      <QueryCard />
      <Result />
    </Page>
  );
};
