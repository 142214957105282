import { FC } from "react";
import { Provider } from "react-redux";
import { Home, Claim } from "./pages";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import store from "state";
import { ThemeProvider } from "./theme";

export const App: FC = () => {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="claim/:chain" element={<Claim />} />
            <Route path="claim" element={<Claim />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
