import { createReducer } from "@reduxjs/toolkit";
import { setTermAccept, setTermRemarkHash, setTermSignature, clearTermItem, TermType } from "./actions";
import { getId, loadTerm } from "./helpers";

export interface TermState {
  readonly byId: {
    readonly [id: string]: {
      readonly address: string;
      readonly type: TermType;
      readonly data: string;
      readonly date: number;
    };
  };
}

const initialState: TermState = {
  byId: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setTermAccept, (state, { payload: { address, isAccept, date } }) => {
      const id = getId(address, "remarkHash");

      state.byId[id] = {
        address: address,
        type: "remarkHash",
        data: "1",
        date: date,
      };
    })
    .addCase(setTermRemarkHash, (state, { payload: { address, data, date } }) => {
      const id = getId(address, "remarkHash");

      state.byId[id] = {
        address: address,
        type: "remarkHash",
        data,
        date: date,
      };
    })
    .addCase(setTermSignature, (state, { payload: { address, data, date } }) => {
      const id = getId(address, "signature");

      state.byId[id] = {
        address: address,
        type: "signature",
        data,
        date: date,
      };
    })
    .addCase(clearTermItem, (state, { payload: { address } }) => {
      const signatureId = getId(address, "signature");
      const remarkHashId = getId(address, "remarkHash");

      delete state.byId[signatureId];
      delete state.byId[remarkHashId];
    })
);
