import styled from "@emotion/styled";
import { FC, useState, useMemo } from "react";
import { useQueryResult } from "state";
import { ReactComponent as AcalaLogoGrey } from "../../../assest/tab-acala-logo-grey.svg";
import { ReactComponent as AcalaLogoGradient } from "../../../assest/tab-acala-logo-gradient.svg";
import { ReactComponent as KaruraLogoGrey } from "../../../assest/tab-karura-logo-grey.svg";
import { ReactComponent as KaruraLogoGradient } from "../../../assest/tab-karura-logo-gradient.svg";
import { Detail } from "./Detail";
import { Box, Container } from "@chakra-ui/react";

const Tabs = styled.div``;
const Tab = styled.div``;

export const Result = styled<FC<{ className?: string }>>(({ className }) => {
  // set karura as default
  const [active, setActive] = useState<number>(0);
  const data = useQueryResult();
  const type = useMemo(() => (active === 0 ? "acala" : "karura"), [active]);

  if (!data) return null;

  return (
    <Box
      className={className}
      id="result"
      bg={type === "acala" ? "#ffffff" : "#1b1b1b"}
      padding={["50px 0", "100px 0"]}
    >
      <Container display="flex" flexDirection="column" alignItems="center" transform="all .2s" maxW={925}>
        <Tabs>
          <Tab className={active === 0 ? "active" : ""} onClick={() => setActive(0)}>
            {active === 0 ? <AcalaLogoGradient /> : <AcalaLogoGrey />}
            <Box className={active === 0 ? "activeText" : ""}>Acala</Box>
          </Tab>
          <Tab className={active === 1 ? "active" : ""} onClick={() => setActive(1)}>
            {active === 1 ? <KaruraLogoGradient /> : <KaruraLogoGrey />}
            <Box className={active === 1 ? "activeText" : ""}>Karura</Box>
          </Tab>
        </Tabs>
        <Detail type={type} data={type === "acala" ? data?.acala : data?.karura} />
      </Container>
    </Box>
  );
})`
  & ${Tabs} {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 536px;
    height: 64px;
    overflow: hidden;

    @media (max-width: 640px) {
      width: 100%;
    }

    &:after {
      content: "";
      border-radius: 8px;
      border: 1px solid #4f4f4f;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
    }
  }

  & ${Tab} {
    z-index: 1;
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #828282;
    cursor: pointer;

    &.active {
      border: 1px solid #ff4c3b;
      background: linear-gradient(319.48deg, #e40c5b -4.53%, #ff4c3b 78.7%);
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    /** compatible with safari */
    .activeText {
      background: linear-gradient(319.48deg, #e40c5b -4.53%, #ff4c3b 78.7%);
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & > svg {
      margin-right: 8px;
    }
  }
`;
