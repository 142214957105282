import instance from "./instance";

export type ClaimInfoResponse = {
  result: boolean;
  totalReward: string;
  claimed: boolean;
  originClaimed: boolean;
};

export const fetchClaimInfo = (address: string, chain: string) => {
  let url = `/claim/claim?address=${address}`;

  if (chain === "acala") {
    url = "/acala" + url;
  }

  return instance.get<ClaimInfoResponse>(url);
};

export interface Statement {
  paraId: number;
  statementMsgHash: string;
  statement: string;
}

export const fetchStatement = (chain: string) => {
  let url = "/claim/statement";

  if (chain === "acala") {
    url = "/acala" + url;
  }

  return instance.get<Statement>(url);
};

export type PostClaimParams = {
  address: string;
  signature: string;
  email: string;
  receiveEmail: boolean;
};

export const postClaim = (params: PostClaimParams, chain: string) => {
  let url = "/claim/claim";

  if (chain === "acala") {
    url = "/acala" + url;
  }

  return instance.post(url, params);
};
