import { Box, Text } from "@chakra-ui/layout";
import { useContext } from "react";
import { FC } from "react";
import { Context } from "./Context";
import { ClaimSuccessProps } from "./types";

export const ClaimSuccess: FC<ClaimSuccessProps> = ({ claimed, originClaimed }) => {
  const { chain } = useContext(Context);

  if (originClaimed) {
    return (
      <Box>
        <Text textAlign="center">The distribution has been sent to your account, please check it</Text>
        <Text textAlign="center">Thanks for your patience!</Text>
      </Box>
    );
  }

  return claimed ? (
    <Box>
      <Text textAlign="center">The scheduled distribution may take up to 48 hours from the moment you claim them.</Text>
      <Text textAlign="center">Thanks for your patience!</Text>
    </Box>
  ) : (
    <Box>
      <Text textAlign="center" fontSize={24} lineHeight={1}>
        Congratulations!
      </Text>
      <Text textAlign="center" mt={3}>
        Your {chain === "acala" ? "ACA" : "KAR"} reward is scheduled to be distributed in the next 48 hours. Thanks for
        your patience!
      </Text>
    </Box>
  );
};
