import { createAction } from "@reduxjs/toolkit";

export type TermType = "remarkHash" | "signature";

export const setTermRemarkHash = createAction<{
  address: string;
  data: string;
  date: number;
}>("term/setTermRemarkHash");

export const setTermSignature = createAction<{
  address: string;
  data: string;
  date: number;
}>("term/setTermSignature");

export const setTermAccept = createAction<{
  address: string;
  isAccept: boolean;
  date: number;
}>("term/setTermAccept");

export const clearTermItem = createAction<{
  address: string;
}>("term/clearTermItem");
