import { Box, Text } from "@chakra-ui/layout";
import { useContext } from "react";
import { Context } from "./ClaimManuallyContext";

export const ClaimSuccess = () => {
  const { chain } = useContext(Context);
  const chainToken = chain === "acala" ? "ACA" : "KAR";

  return (
    <Box mt={6}>
      <Text textAlign="center" fontWeight={700} fontSize={24} lineHeight={1}>
        Congratulations!{" "}
      </Text>
      <Text textAlign="center" mt="24px">
        Your {chainToken} reward is scheduled to be distributed in the next 48 hours. Thanks for your patience!
      </Text>
    </Box>
  );
};

export const OriginClaimedSuccess = () => {
  const { chain } = useContext(Context);
  const chainToken = chain === "acala" ? "ACA" : "KAR";

  return (
    <Box mt={6}>
      <Text textAlign="center" fontWeight={700} fontSize={24} lineHeight={1}>
        Congratulations!{" "}
      </Text>
      <Text textAlign="center" mt="24px">
        Your {chainToken} reward distributed, please check it. Thanks for your patience!
      </Text>
    </Box>
  );
};

export const NoNeedClaim = () => {
  return (
    <Box mt={6}>
      <Text textAlign="center" mt="24px">
        The account don't need claim.
      </Text>
    </Box>
  );
};
