import styled from "styled-components";

const SummaryRoot = styled.div`
  --table-border: #f2f2f2;
  --table-bg: #F8F8F8;
  --table-header: #E0E0E0;
  --table-font-color: #4F4F4F;
  --table-cell-border: #4F4F4F;
  --text-second-color: #4F4F4F;
  --text-primary-color: #333333;

  margin-top: 8px;
  background: var(--table-bg);
  border-radius: 12px;
  border: 1px solid var(--table-border);
  padding: 34px 68px;

  @media (max-width: 640px) {
    padding: 16px 24px;
  }

  display: flex;
  justify-content: space-between;

  @media (max-width: 640px) {
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SummaryContent = styled.div`
  display: flex;
  align-items: center;


  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-primary-color);

  & > .unit {
    margin-left: 4px;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-second-color);
  }
`;

export const SummaryTitle = styled.div`
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--text-second-color);
`;


const SummaryBody = styled.div``;

export const Summary = SummaryRoot as typeof SummaryRoot & {
  Body: typeof SummaryBody;
  Item: typeof SummaryItem;
  Title: typeof SummaryTitle;
  Content: typeof SummaryContent;
};

Summary.Item = SummaryItem;
Summary.Title = SummaryTitle;
Summary.Content = SummaryContent;