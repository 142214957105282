import { AppState } from "../index";
import { getId } from "./helpers";
import { selectedAddressSelector } from "../account/selectors";
import { createSelector } from "reselect";

export const termByIdSelector = (state: AppState) => state.term.byId;

export const termSignatureSelector = createSelector(selectedAddressSelector, termByIdSelector, (address, byid) => {
  if (!address) return false;
  const id = getId(address, "signature");
  return byid[id]?.data || false;
});
