import { isHex } from "@polkadot/util";
import { decodeAddress } from "@polkadot/util-crypto";
import * as Yup from "yup";

export const addressSchema = Yup.string()
  .required()
  .test("checkAddressValid", "invalid address", (encoded) => {
    if (!encoded) return false;

    try {
      decodeAddress(encoded, false);

      return true;
    } catch {
      return false;
    }
  });
// .test("checkAddressPrefix", "invalid address prefix, it should be a kusama address", (encoded) => {
//   if (!encoded) return false;

//   try {
//     decodeAddress(encoded, false, (process.env as any).REACT_APP_SS58 || 2);

//     return true;
//   } catch {
//     return false;
//   }
// });

export const referralCodeSchema = Yup.string()
  .test("isHex", "invalid referral code", (str) => {
    if (!str) return true;
    if (!isHex(str)) {
      return false;
    }
    return true;
  })
  .test("hexLength", "invalid referral code length", (str) => {
    if (!str) return true;
    if (!isHex(str, 256)) {
      return false;
    }
    return true;
  });

export const blockHashSchema = Yup.string()
  .required()
  .test("isHex", "invalid hash", (str) => {
    if (!str) return true;
    if (!isHex(str)) {
      return false;
    }
    return true;
  })
  .test("hexLength", "invalid length", (str) => {
    if (!str) return true;
    if (!isHex(str, 256)) {
      return false;
    }
    return true;
  });

export const signatureSchema = Yup.string()
  .required()
  .test("isHex", "invalid hex", (str) => {
    if (!isHex(str)) {
      return false;
    }
    return true;
  })
  .test("isSignature", "invalid signature", (str) => {
    if (!isHex(str, 512)) {
      return false;
    }
    return true;
  });
