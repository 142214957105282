type ErrorResponse = {
    statusCode: number;
    error?: string;
    message?: string;
};

export class ApiError extends Error {
    public readonly message: string;
    public readonly response: ErrorResponse;
    public readonly _isApiError: boolean;

    constructor(message: string, response: ErrorResponse) {
        super(message);

        Object.setPrototypeOf(this, new.target.prototype);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError);
        }

        this.response = response;
        this.message = message;
        this._isApiError = true;
    }

    static isApiError(obj: any): obj is ApiError {
        return obj._isApiError;
    }
}
