import { FixedPointNumber } from "@acala-network/sdk-core";
import { Box } from "@chakra-ui/layout";
import { Button, Center, Spinner, useToast } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { DEFAULT_TERMS_CHECK_DATA, Terms, TermsCheckData } from "../Terms";
import { ClaimSuccess } from "./ClaimSuccess";
import { ClaimRequestProps } from "./types";
import { getSigner } from "utils/getSigner";
import { useAppDispatch, useAppSelector } from "state";
import { selectedAccountSelector, selectedAddressSelector } from "state/account/selectors";
import { stringToHex } from "@polkadot/util";
import { setTermSignature } from "state/term/actions";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { termSignatureSelector } from "state/term/selectors";
import { useRequest } from "hooks";
import { postClaim } from "api";
import { ClaimAmount } from "./ClaimAmount";
import { useContext } from "react";
import { Context } from "./Context";

export const ClaimRequest: FC<ClaimRequestProps> = ({ isLoading, originClaimed, claimed, amount, statement }) => {
  const { chain } = useContext(Context);
  const selectedAccount = useAppSelector(selectedAccountSelector);
  const mountedRef = useIsMountedRef();
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const signedString = useAppSelector(termSignatureSelector);
  const [{ check1, check2, check3, email, emailValid }, setChecked] =
    useState<TermsCheckData>(DEFAULT_TERMS_CHECK_DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClaimSuccess, setClaimSuccess] = useState(false);

  const postClaimRequest = useRequest("Querying amount...", postClaim);

  const toast = useToast();

  useEffect(() => {
    if (!selectedAccount?.address) return;

    setClaimSuccess(false);
    setIsSubmitting(false);
    setChecked(DEFAULT_TERMS_CHECK_DATA);
  }, [setClaimSuccess, setIsSubmitting, setChecked, selectedAccount]);

  const onSubmit = useCallback(async () => {
    let signed = signedString;
    if (!signed) {
      const accepted = check1 && check2;
      if (!accepted) return;

      if (check3 && !emailValid) return;

      setIsSubmitting(true);
      const signer = await getSigner(selectedAddress);

      if (!signer || !signer.signRaw) {
        toast({
          status: "error",
          description: `Can't find signer`,
          position: "top-right",
          duration: 5000,
        });
        return;
      }

      try {
        const signature = await signer.signRaw({
          address: selectedAddress,
          type: "bytes",
          data: stringToHex(statement),
        });

        dispatch(
          setTermSignature({
            address: selectedAddress,
            data: signature.signature,
            date: +new Date(),
          })
        );

        signed = signature.signature;
        setIsSubmitting(false);
      } catch (error: any) {
        toast({
          status: "error",
          description: error?.message,
          position: "top-right",
          duration: 10000,
        });
        mountedRef.current && setIsSubmitting(false);
        return;
      }
    }

    postClaimRequest
      .run(
        {
          address: selectedAddress,
          signature: signed,
          email: emailValid ? email : "",
          receiveEmail: check3,
        },
        chain
      )
      .then(({ data }) => {
        toast({
          title: "Sign Terms",
          status: "success",
          position: "top-right",
        });

        setClaimSuccess(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [
    chain,
    signedString,
    postClaimRequest,
    selectedAddress,
    emailValid,
    email,
    check3,
    check1,
    check2,
    toast,
    statement,
    dispatch,
    mountedRef,
  ]);

  return (
    <Box>
      <ClaimAmount
        isLoading={isLoading}
        claimed={isClaimSuccess || claimed}
        originClaimed={originClaimed}
        amount={amount}
      />
      {isLoading ? (
        <Center mt={12} mb={4}>
          <Spinner color="white" size="lg" />
        </Center>
      ) : !claimed && amount > FixedPointNumber.ZERO && !signedString ? (
        <Terms onChange={(data) => setChecked(data)} chain={chain} />
      ) : null}
      {isLoading ? null : isClaimSuccess || claimed ? (
        <Box mt={10} mb={4}>
          <ClaimSuccess claimed={claimed} originClaimed={originClaimed} />
        </Box>
      ) : amount > FixedPointNumber.ZERO ? (
        <Button
          w="100%"
          h={58}
          mt={["75px"]}
          fontSize={"20px"}
          lineHeight="24px"
          fontWeight={500}
          variant="primary-gradient"
          onClick={onSubmit}
          onTouchEnd={onSubmit}
          isLoading={isSubmitting || postClaimRequest.isLoading}
        >
          Claim Now
        </Button>
      ) : null}
    </Box>
  );
};
