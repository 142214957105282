import { Box, Flex, Text } from "@chakra-ui/react";
import { format } from "d3-format";
import { useContext } from "react";
import { FC } from "react";
import { Context } from "./Context";
import { ClaimAmountProps } from "./types";

const formatNumber = format(",.2f");

export const ClaimAmount: FC<ClaimAmountProps> = ({ isLoading, claimed, originClaimed, amount }) => {
  const { chain } = useContext(Context);

  const chainToken = chain === "acala" ? "ACA" : "KAR";

  return (
    <Box>
      <Flex
        bg="grey.1"
        borderRadius={6}
        padding={["16px 24px"]}
        h={62}
        mt={"36px"}
        align="center"
        justify="space-between"
        color="white"
      >
        <Flex align="center">
          <Text as="span" display="inline-block" fontSize={"32px"} fontWeight={700} mr={"4px"}>
            {isLoading ? "--.--" : formatNumber(amount.toNumber())}
          </Text>
          <Text as="span" display="inline-block" fontSize={"20px"} fontWeight={500}>
            {chainToken}
          </Text>
        </Flex>
        {isLoading ? null : (
          <Box
            display={["none", "inline"]}
            h={["28px"]}
            borderRadius="18px"
            border="1px solid"
            borderColor="origin.1"
            bg="origin.1-10%"
            padding={["0 14px"]}
          >
            <Text color="origin.1" fontSize={12} lineHeight={"26px"} fontWeight={500}>
              {originClaimed ? "Distributed" : claimed ? "Scheduled for distribution" : "TO BE CLAIMED"}
            </Text>
          </Box>
        )}
      </Flex>
      {isLoading ? null : (
        <Box
          display={["block", "none"]}
          h={["28px"]}
          borderRadius="18px"
          border="1px solid"
          borderColor="origin.1"
          bg="origin.1-10%"
          padding={["0 14px"]}
          mt={[2, 0]}
        >
          <Text textAlign="center" color="origin.1" fontSize={12} lineHeight={"26px"} fontWeight={500}>
            {originClaimed ? "Distributed" : claimed ? "Scheduled for distribution" : "TO BE CLAIMED"}
          </Text>
        </Box>
      )}
    </Box>
  );
};
