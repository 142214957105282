// Number.isNaN as it is not supported in IE11 so conditionally using ponyfill
// Using Number.isNaN where possible as it is ~10% faster

const safeIsNaN =
    Number.isNaN ||
    function ponyfill(value: unknown): boolean {
        // eslint-disable-next-line no-self-compare
        return typeof value === 'number' && value !== value;
    };

function isEqual(first: unknown, second: unknown): boolean {
    if (first === second) {
        return true;
    }

    // Special case for NaN (NaN !== NaN)
    if (safeIsNaN(first) && safeIsNaN(second)) {
        return true;
    }

    return false;
}

export function areInputsEqual(
    newInputs: readonly unknown[],
    lastInputs: readonly unknown[]
): boolean {
    // no checks needed if the inputs length has changed
    if (newInputs.length !== lastInputs.length) {
        return false;
    }
    // Using for loop for speed. It generally performs better than array.every
    // https://github.com/alexreardon/memoize-one/pull/59
    for (let i = 0; i < newInputs.length; i++) {
        if (!isEqual(newInputs[i], lastInputs[i])) {
            return false;
        }
    }
    return true;
}

// Using ReadonlyArray<T> rather than readonly T as it works with TS v3
export type EqualityFn = (newArgs: any[], lastArgs: any[]) => boolean;

export function memoizeOne<
    // Need to use 'any' rather than 'unknown' here as it has
    // The correct Generic narrowing behaviour.
    ResultFn extends (this: any, ...newArgs: any[]) => ReturnType<ResultFn>
>(resultFn: ResultFn, isEqual: EqualityFn = areInputsEqual): ResultFn {
    let lastThis: unknown;
    let lastArgs: unknown[] = [];
    let lastResult: ReturnType<ResultFn>;
    let calledOnce: boolean = false;

    // breaking cache when context (this) or arguments change
    function memoized(
        this: unknown,
        ...newArgs: unknown[]
    ): ReturnType<ResultFn> {
        if (calledOnce && lastThis === this && isEqual(newArgs, lastArgs)) {
            return lastResult;
        }

        // Throwing during an assignment aborts the assignment: https://codepen.io/alexreardon/pen/RYKoaz
        // Doing the lastResult assignment first so that if it throws
        // nothing will be overwritten
        lastResult = resultFn.apply(this, newArgs);
        calledOnce = true;
        lastThis = this;
        lastArgs = newArgs;
        return lastResult;
    }

    return memoized as ResultFn;
}

// default export
export default memoizeOne;
