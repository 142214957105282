import { Flex, Box } from "@chakra-ui/react";
import { truncated } from "../utils/truncated";
import React from "react";
import { IdentityIcon } from "./IdentityIcon";

type AddressProps = {
  address: string | null;
  name?: string;
  withName?: boolean;
  view: "name" | "address" | "full";
  length?: number;
  addressColor?: string;
} & React.ComponentProps<typeof Box>;

export const Address: React.FC<AddressProps> = ({ address, name, view, length = 12, addressColor, ...rest }) => {
  if (!address) return null;

  const shortAddress = truncated(address, length);
  const label = view === "address" || view === "full" || name ? (name ? name : shortAddress) : "";

  const size = view === "full" ? 32 : 24;

  return (
    // <Tooltip
    //     label={address}
    //     aria-label="account address"
    //     placement="top"
    //     hasArrow
    //     {...rest}
    // >
    <Flex display="inline-flex" alignItems="center" textTransform="uppercase">
      <IdentityIcon size={size} value={address} />
      <Box marginLeft="12px" fontSize="md">
        {view === "address" ? (
          <Box color={addressColor}>{shortAddress}</Box>
        ) : view === "name" ? (
          <Box>{label}</Box>
        ) : view === "full" ? (
          <>
            <Box fontSize="14px" textAlign="left">
              {label}
            </Box>
            <Box color={addressColor} mt="2px" fontSize="12px">
              {shortAddress}
            </Box>
          </>
        ) : null}
      </Box>
    </Flex>
    // </Tooltip>
  );
};
