import { Box, Flex, Link, styled } from "@chakra-ui/react";
import { ReactComponent as Logo } from "../assest/acalaLogo.svg";

export const Page = styled(Box, {
  baseStyle: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    minH: "100vh",
  },
});

export const PageHeader = () => {
  return (
    <Box bg="white">
      <Flex
        as="header"
        pl={[2, 0]}
        h={90}
        alignItems="center"
        justifyContent="flex-start"
        maxW="container.lg"
        margin="0 auto"
      >
        <Link href="https://acala.network" target="_blank">
          <Logo />
        </Link>
      </Flex>
    </Box>
  );
};
