import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { createReducer } from '@reduxjs/toolkit';
import { updateAccount } from './actions';

export interface AccountState {
    readonly accountList: InjectedAccountWithMeta[] | null;
    readonly selectedAddress: string;
}

const initialState: AccountState = {
    accountList: null,
    selectedAddress: '',
};

export default createReducer(initialState, (builder) =>
    builder.addCase(updateAccount, (state, { payload: { list, address } }) => {
        if (list) {
            state.accountList = list;
        }

        if (!state.selectedAddress && !address) {
            state.selectedAddress = state.accountList?.[0]?.address || '';
        } else {
            const targetAddress = address || state.selectedAddress;
            const findedAccount = state.accountList?.find(
                ({ address }) => targetAddress === address
            );

            if (findedAccount) {
                state.selectedAddress = findedAccount.address;
            } else {
                state.selectedAddress = state.accountList?.[0]?.address || '';
            }
        }
    })
);
