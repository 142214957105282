import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Table, Summary, DistributedStatus, List, ToBeDistributedStatus, ToBeClaimedStatus } from "../../../components";
import { FixedPointNumber } from "@acala-network/sdk-core/fixed-point-number";
import { ReactComponent as ArrowIconSVG } from "../../../assest/arrow.svg";
import { format } from "d3-format";
import { get } from "lodash";
import { Text, Image, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Amount = styled.div``;
const EventTitle = styled.div``;
const TotalAmount = styled(Summary.Item)``;

const CLink = styled(Link)`
  font-size: 12px;
  font-weight: 400;
  color: #518aff !important;
  text-decoration: underline;
`;

const ArrowIcon = styled(ArrowIconSVG)<{ open: boolean }>`
  transform: rotateZ(${({ open }) => (open ? "0" : "180deg")});
`;

const Root = styled.div<{ type: string }>`
  margin-top: 65px;
  width: 100%;

  & ${Table}, & ${Summary} {
    --table-border: ${({ type }) => (type === "acala" ? "#f2f2f2" : "#4f4f4f")};
    --table-bg: ${({ type }) => (type === "acala" ? "#F8F8F8" : "#333333")};
    --table-header: ${({ type }) => (type === "acala" ? "#E0E0E0" : "#2D2D2D")};
    --table-font-color: ${({ type }) => (type === "acala" ? "#4F4F4F" : "#FFFFFF")};
    --table-cell-border: ${({ type }) => (type === "acala" ? "#E0E0E0" : "#4F4F4F")};

    --text-second-color: ${({ type }) => (type === "acala" ? "#4F4F4F" : "#E0E0E0")};
    --text-primary-color: ${({ type }) => (type === "acala" ? "#333333" : "#F2F2F2")};
  }

  & ${EventTitle} {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }

  & ${Amount} {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  & ${TotalAmount} {
    flex: 0 0 calc(40% + 72px);
    margin-right: -72px;
    border-left: 1px solid var(--table-cell-border);

    @media (max-width: 640px) {
      margin-right: 0;
      border-left: none;
    }

    ${Summary.Content} {
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
    }
  }
`;

const formatNumber = format("~");

const createSum = (data: any[]) => (property: string) => {
  return data.reduce((acc, cur) => {
    return acc + get(cur, property, 0);
  }, 0);
};

const useBoolean = () => {
  const [value, setValue] = useState<Record<string, boolean>>({});
  const ref = useRef<Record<string, boolean>>({});

  const toggle = useCallback(
    (key) => {
      const data = {
        ...ref.current,
        [key]: Reflect.has(ref.current, key) ? !ref.current[key] : false,
      };

      ref.current = data;
      setValue(data);
    },
    [setValue]
  );

  const getState = useCallback(
    (key: string) => {
      return Reflect.has(value, "key") ? value[key] : true;
    },
    [value]
  );

  return { state: value, toggle, getState };
};

export const Detail: FC<{ type: string; data: any }> = ({ type, data }) => {
  const tokenName = useMemo(() => (type === "acala" ? "ACA" : "KAR"), [type]);

  const airdrop = useMemo(() => {
    const airdrop = (data?.airdrop as any[]) || [];
    const total = airdrop.reduce((acc, cur) => acc + cur.amount, 0) as number;

    if (!airdrop) return;

    return {
      total,
      list: airdrop,
      transferable: total,
      toBeClaimed: 0,
      vested: 0,
    };
  }, [data]);

  const crowdloans = useMemo(() => {
    const list = data?.crowdloans as any[];

    if (!list || list.length === 0) return;

    const decimal = 12;
    const tokenDecimal = type === "acala" ? 10 : 12;

    return list.map((item) => {
      const format = (i: string) => (i ? i.replaceAll(",", "") : "0");
      const total = FixedPointNumber.fromInner(format(item?.totalReward) || 0, decimal);
      const isToBeClaimed = item["status"] === "To be Claimed";

      console.log(isToBeClaimed);

      return {
        total: total.toNumber(),
        ratio: item?.detail.ratio,
        totalLocked: FixedPointNumber.fromInner(
          (type === "acala" ? format(item?.totalDOTLocked) : format(item?.totalKSMLocked)) || 0,
          tokenDecimal
        ).toNumber(),
        contribution: FixedPointNumber.fromInner(
          (type === "acala" ? format(item?.detail?.baseBonus) : format(item?.detail?.contribution)) || 0,
          decimal
        ).toNumber(),
        totalReward: FixedPointNumber.fromInner(format(item?.totalReward) || 0, decimal).toNumber(),
        referreeBouns: FixedPointNumber.fromInner(
          (type === "acala" ? format(item?.detail?.referreeBonus) : format(item?.detail?.referreeBouns)) || 0,
          decimal
        ).toNumber(),
        referralBouns: FixedPointNumber.fromInner(
          (type === "acala" ? format(item?.detail?.referralBonus) : format(item?.detail?.referralBouns)) || 0,
          decimal
        ).toNumber(),
        earlyBird: FixedPointNumber.fromInner(
          (type === "acala" ? format(item?.detail?.crowdloanKickoff) : format(item?.detail?.earlyBird)) || 0,
          decimal
        ).toNumber(),
        auctionKickoff: FixedPointNumber.fromInner(format(item?.detail?.auctionKickoff) || 0, decimal).toNumber(),
        karuraBonus: FixedPointNumber.fromInner(format(item?.detail?.karuraBonus) || 0, decimal).toNumber(),
        lcAmount: FixedPointNumber.fromInner(format(item?.detail?.lcAmount) || 0, 10).toNumber(),
        questBonus: FixedPointNumber.fromInner(format(item?.detail?.questBonus) || 0, decimal).toNumber(),
        other: FixedPointNumber.fromInner(format(item?.detail?.other) || 0, decimal).toNumber(),
        transferable: isToBeClaimed
          ? 0
          : total.times(type === "acala" ? new FixedPointNumber(0.2) : new FixedPointNumber(0.3)).toNumber(),
        vested: isToBeClaimed
          ? 0
          : total.times(type === "acala" ? new FixedPointNumber(0.8) : new FixedPointNumber(0.7)).toNumber(),
        toBeClaimed: isToBeClaimed ? total.toNumber() : 0,
        status: item.status,
        isToBeClaimed,
      };
    });
  }, [data?.crowdloans, type]);

  const buildAcala = useMemo(() => {
    if (data?.buileAcala) return;

    const total = data.buildAcala;

    return {
      total,
      transferable: total,
    };
  }, [data]);

  const buildAcala2 = useMemo(() => {
    const community = data.buildAcala2.community;
    const pub = data.buildAcala2.public;

    return {
      total: community + pub,
      community,
      pub,
    };
  }, [data]);

  const summary = useMemo(() => {
    const sum = createSum([...(crowdloans ?? []), airdrop, buildAcala, buildAcala2]);

    const total = sum("total");
    const lcAmount = sum("lcAmount");
    const transferable = sum("transferable");

    // distributed = transferable
    const distributed = transferable;

    const toBeClaimed = sum("toBeClaimed");

    const toBeDistribute = total - distributed - toBeClaimed;

    const vested = total - transferable;

    return { total, distributed, toBeClaimed, toBeDistribute, vested, transferable, lcAmount };
  }, [crowdloans, airdrop, buildAcala, buildAcala2]);

  const nft = useMemo(() => {
    const nft = data.nft || [];

    return nft as { imageSrc: string; tokenInfo: { description: string; name: string } }[];
  }, [data]);

  const { state, toggle } = useBoolean();

  return (
    <Root type={type}>
      <Text
        mb={["32px", "64px"]}
        textAlign="center"
        fontWieght={600}
        fontSize={[24, 32]}
        lineHeight={1}
        color={type === "acala" ? "#000" : "#f2f2f2"}
      >
        Your {type === "acala" ? "ACA" : "KAR"} distribution details
      </Text>
      {summary && summary.total ? (
        <Table>
          <Table.Header>
            <Table.Cell>EVENT</Table.Cell>
            <Table.Cell>STATUS</Table.Cell>
            <Table.Cell>AMOUNT</Table.Cell>
          </Table.Header>
          <Table.Body>
            {airdrop && airdrop.total ? (
              <>
                <Table.Row>
                  <Table.Cell>
                    <EventTitle onClick={() => toggle("airdrop")}>
                      Airdrop
                      <ArrowIcon open={state["airdrop"] ?? true} />
                    </EventTitle>
                  </Table.Cell>
                  <Table.Cell>
                    <DistributedStatus />
                  </Table.Cell>
                  <Table.Cell>
                    <Amount>{`${formatNumber(airdrop.total)} ${tokenName}`}</Amount>
                  </Table.Cell>
                </Table.Row>
                {state["airdrop"] ?? true ? (
                  <List>
                    {airdrop.list.map((item: any, index) => {
                      return (
                        <List.Item key={`${item.tag}-${index}-${item.amount}`}>
                          <List.Cell>{item.tag}</List.Cell>
                          <List.Cell>{`${formatNumber(item.amount)} ${tokenName}`}</List.Cell>
                        </List.Item>
                      );
                    })}
                  </List>
                ) : null}
              </>
            ) : null}
            {buildAcala && buildAcala.total ? (
              <Table.Row>
                <Table.Cell>
                  <EventTitle>Build Acala #1</EventTitle>
                </Table.Cell>
                <Table.Cell>
                  <ToBeDistributedStatus />
                </Table.Cell>
                <Table.Cell>
                  <Amount>{`${formatNumber(buildAcala.total)} ${tokenName}`}</Amount>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {buildAcala2 && buildAcala2.total ? (
              <React.Fragment>
                <Table.Row>
                  <Table.Cell>
                    <EventTitle>Build Acala #2</EventTitle>
                  </Table.Cell>
                  <Table.Cell>
                    <ToBeDistributedStatus />
                  </Table.Cell>
                  <Table.Cell>
                    <Amount>{`${formatNumber(buildAcala2.total)} ${tokenName}`}</Amount>
                  </Table.Cell>
                </Table.Row>
                <List className="noBorder">
                  <List.Item className="title">
                    <List.Title>Community Exclusive</List.Title>
                    <List.Cell>
                      <Amount>{`${formatNumber(buildAcala2.community)} ${tokenName}`}</Amount>
                    </List.Cell>
                  </List.Item>
                  <List.Item className="title">
                    <List.Title>Public</List.Title>
                    <List.Cell>
                      <Amount>{`${formatNumber(buildAcala2.pub)} ${tokenName}`}</Amount>
                    </List.Cell>
                  </List.Item>
                </List>
              </React.Fragment>
            ) : null}
            {crowdloans
              ? crowdloans.map((crowdloan, index) => {
                  return (
                    <React.Fragment key={`crowdloan-${index}`}>
                      <Table.Row>
                        <Table.Cell>
                          <EventTitle onClick={() => toggle(`crowdloan-${index}`)}>
                            Crowdloan
                            <ArrowIcon open={state[`crowdloan-${index}`] ?? true} />
                          </EventTitle>
                        </Table.Cell>
                        <Table.Cell>
                          {crowdloan.isToBeClaimed ? (
                            <Flex alignItems="center" sx={{ columnGap: 8 }}>
                              <ToBeClaimedStatus />
                              <CLink to={`/claim/${type}`}> Go To Claim </CLink>
                            </Flex>
                          ) : (
                            <ToBeDistributedStatus />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <Flex direction="column">
                            <Amount>{`${formatNumber(crowdloan.total)} ${tokenName}`}</Amount>
                            {type === "acala" && <Amount>{`${formatNumber(crowdloan.lcAmount)} lcDOT`}</Amount>}
                          </Flex>
                        </Table.Cell>
                      </Table.Row>
                      {state[`crowdloan-${index}`] ?? true ? (
                        <List className="noBorder">
                          <List.Item className="title">
                            <List.Title>Total {type === "acala" ? "DOT" : "KSM"} locked :</List.Title>
                            <List.Cell>{`${formatNumber(crowdloan.totalLocked)} ${
                              type === "acala" ? "DOT" : "KSM"
                            }`}</List.Cell>
                          </List.Item>
                          <List.Item className="title">
                            <List.Title>Vesting schedule :</List.Title>
                          </List.Item>
                          <List.Item>
                            <List.Cell>Transferable</List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.transferable)} ${tokenName}`}</List.Cell>
                          </List.Item>
                          <List.Item>
                            <List.Cell>Vested over {type === "acala" ? 96 : 48} weeks</List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.vested)} ${tokenName}`}</List.Cell>
                          </List.Item>
                          <List.Item className="title">
                            <List.Title>Rewards breakdown :</List.Title>
                          </List.Item>
                          <List.Item>
                            <List.Cell>
                              Contribution reward (1 {type === "acala" ? "DOT" : "KSM"} : {crowdloan.ratio}{" "}
                              {type === "acala" ? "ACA" : "KAR"})
                            </List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.contribution)} ${tokenName}`}</List.Cell>
                          </List.Item>
                          <List.Item>
                            <List.Cell>Referee Bonus</List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.referreeBouns)} ${tokenName}`}</List.Cell>
                          </List.Item>
                          <List.Item>
                            <List.Cell>Referral Bonus</List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.referralBouns)} ${tokenName}`}</List.Cell>
                          </List.Item>
                          {type === "acala" && (
                            <List.Item>
                              <List.Cell>Quest Bonus</List.Cell>
                              <List.Cell>{`${formatNumber(crowdloan.questBonus)} ${tokenName}`}</List.Cell>
                            </List.Item>
                          )}
                          <List.Item>
                            <List.Cell>
                              {type === "acala" ? "Crowdloan Kickoff" : "Early bird"} {type === "acala" ? "" : "(10%)"}
                            </List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.earlyBird)} ${tokenName}`}</List.Cell>
                          </List.Item>
                          <List.Item>
                            <List.Cell>Auction Kickoff</List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.auctionKickoff)} ${tokenName}`}</List.Cell>
                          </List.Item>
                          {type === "acala" && (
                            <List.Item>
                              <List.Cell>Karura Bonus</List.Cell>
                              <List.Cell>{`${formatNumber(crowdloan.karuraBonus)} ${tokenName}`}</List.Cell>
                            </List.Item>
                          )}
                          <List.Item>
                            <List.Cell>Other</List.Cell>
                            <List.Cell>{`${formatNumber(crowdloan.other)} ${tokenName}`}</List.Cell>
                          </List.Item>
                        </List>
                      ) : null}
                    </React.Fragment>
                  );
                })
              : null}
          </Table.Body>
        </Table>
      ) : null}
      {summary ? (
        <Summary>
          <Summary.Item>
            <Summary.Title>Total distributed</Summary.Title>
            <Summary.Content>
              {formatNumber(summary.distributed)} <span className="unit">{tokenName}</span>
            </Summary.Content>
          </Summary.Item>
          <Summary.Item>
            <Summary.Title>Total to be distributed</Summary.Title>
            <Summary.Content>
              {formatNumber(summary.toBeDistribute)} <span className="unit">{tokenName}</span>
            </Summary.Content>
          </Summary.Item>
          <Summary.Item>
            <Summary.Title>Total to be claimed</Summary.Title>
            <Summary.Content>
              {formatNumber(summary.toBeClaimed)} <span className="unit">{tokenName}</span>
            </Summary.Content>
          </Summary.Item>
        </Summary>
      ) : null}
      {summary ? (
        <Summary>
          <Summary.Item>
            <Summary.Title>Transferable</Summary.Title>
            <Summary.Content>
              {formatNumber(summary.transferable)} <span className="unit">{tokenName}</span>
            </Summary.Content>
          </Summary.Item>
          <Summary.Item>
            <Summary.Title>Vested</Summary.Title>
            <Summary.Content>
              {formatNumber(summary.vested)} <span className="unit">{tokenName}</span>
            </Summary.Content>
          </Summary.Item>
          <TotalAmount>
            <Summary.Title>Total</Summary.Title>
            <Summary.Content>
              {formatNumber(summary.total)} <span className="unit">{tokenName}</span>
            </Summary.Content>
            {type === "acala" && summary.lcAmount !== 0 && (
              <Summary.Content>
                {formatNumber(summary.lcAmount)} <span className="unit">lcDOT</span>
              </Summary.Content>
            )}
          </TotalAmount>
        </Summary>
      ) : null}
      {nft.length !== 0 ? (
        <>
          <Text
            m={["32px 0", "64px 0"]}
            textAlign="center"
            fontWieght={600}
            fontSize={[24, 32]}
            lineHeight={1}
            color={type === "acala" ? "#000" : "#f2f2f2"}
          >
            NFTS
          </Text>

          <Flex wrap="wrap" sx={{ rowGap: 30 }}>
            {nft.map((item) => (
              <Image
                w={["120px", "227px"]}
                h={["120px", "227px"]}
                border="2px solid #333333"
                borderRadius={["6px", "15px"]}
                src={item.imageSrc}
                key={item.imageSrc}
              />
            ))}
          </Flex>
        </>
      ) : null}
    </Root>
  );
};
