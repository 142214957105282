import { Button, Box, Text, Input, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchClaimInfo, fetchStatement } from "api";
import { useRequest } from "hooks";
import { isEmpty } from "lodash";
import { FC, useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { addressSchema } from "utils/validateSchema";
import * as Yup from "yup";
import { Context } from "./ClaimManuallyContext";

const scheme = Yup.object().shape({
  address: addressSchema,
});

export const EnterAddress: FC = () => {
  const { setStep, setClaimInfo, setStatement } = useContext(Context);
  const {
    watch,
    formState: { errors },
    register,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(scheme),
  });
  const { chain } = useContext(Context);

  const { run: fetchInfo, isLoading } = useRequest("Querying amount...", fetchClaimInfo);

  const address = watch("address");

  const onNext = useCallback(() => {
    if (!isEmpty(errors)) return;

    if (!address) return;

    fetchInfo(address, chain).then((result) => {
      if (result.status === 200) {
        setClaimInfo({ address, ...result.data });
        setStep(1);
      }
    });
  }, [errors, address, fetchInfo, chain, setClaimInfo, setStep]);

  useEffect(() => {
    fetchStatement(chain).then(({ data }) => setStatement(data));
  }, [setStatement, chain]);

  return (
    <Box mt={[4]}>
      <FormControl isInvalid={errors.address?.message}>
        <Text variant="label">Participating Address</Text>
        <Input variant="primary" autoComplete="off" {...register("address")} />
        <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
        <Button
          mt={8}
          variant="primary-gradient"
          w="100%"
          h={"56px"}
          fontSize={16}
          fontWeight={500}
          onClick={onNext}
          isLoading={isLoading}
        >
          Check
        </Button>
      </FormControl>
    </Box>
  );
};
