import axios from "axios";
import { ApiError } from "./ApiError";

const instance = axios.create({
  baseURL: "https://api.polkawallet.io/",
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.message) {
      return Promise.reject(new ApiError(error.response.data.message, error.response.data));
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
