import styled from "styled-components";

export const Status = styled(({ className, children }) => {
  return <div className={className}>{children}</div>;
})`
  display: inline-block;
  padding: 6px 14px;
  border-radius: 18.5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
`;

export const DistributedStatus = styled(({ className }) => {
  return <Status className={className}>DISTRIBUTED</Status>;
})`
  background: rgba(153, 228, 127, 0.1);
  border: 1px solid #99e47f;
  color: #99e47f;
`;

export const ToBeDistributedStatus = styled(({ className }) => {
  return <Status className={className}>TO BE DISTRIBUTED</Status>;
})`
  background: rgba(235, 110, 89, 0.1);
  border: 1px solid #eb6e59;
  color: #eb6e59;
`;

export const ToBeClaimedStatus = styled(({ className }) => {
  return <Status className={className}>TO BE CLAIMED</Status>;
})`
  background: rgba(235, 110, 89, 0.1);
  border: 1px solid #eb6e59;
  color: #eb6e59;
`;
