import { Statement } from "api";
import React, { FC, PropsWithChildren, useMemo, useState } from "react";

export interface ClaimInfo {
  address: string;
  result: boolean;
  totalReward: string;
  originClaimed: boolean;
  claimed: boolean;
}

interface ContextData {
  chain: string;
  step: number;
  setStep: (step: number) => void;
  setClaimInfo: (data: ClaimInfo) => void;
  claimInfo: ClaimInfo;
  statement: Statement | undefined;
  setStatement: (data: Statement) => void;
}

export const Context = React.createContext({} as unknown as ContextData);

export const Provider: FC<PropsWithChildren<{ chain: string }>> = ({ chain, children }) => {
  const [step, setStep] = useState<number>(0);
  const [claimInfo, setClaimInfo] = useState<ClaimInfo>({
    address: "",
    result: true,
    totalReward: "0",
    originClaimed: false,
    claimed: false,
  });
  const [statement, setStatement] = useState<Statement>();

  const value = useMemo(() => {
    return {
      chain,
      step,
      setStep,
      claimInfo,
      setClaimInfo,
      statement,
      setStatement,
    };
  }, [chain, step, claimInfo, statement]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
