import { load, save } from "../utils/storage";
import { TermType } from "./actions";

const SIGNATURE_OF_TERMS = "SIGNATURE_OF_TERMS";

export const getId = (address: string, type: TermType) => {
  if (!address) {
    throw new Error("Address not found");
  }

  return `${address}-${type}`;
};

export const loadTerm = () => {
  return load(SIGNATURE_OF_TERMS) || {};
};

export const saveTerm = (data: any) => {
  return save(SIGNATURE_OF_TERMS, data);
};
