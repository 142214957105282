import { Box } from "@chakra-ui/react";
import { FC, useContext } from "react";
import { EnterAddress } from "./EnterAddress";
import { ClaimConfirm } from "./ClaimConfirm";
import { Provider, Context } from "./ClaimManuallyContext";

const Inner: FC = () => {
  const { step } = useContext(Context);

  if (step === 0) return <EnterAddress />;
  if (step === 1) return <ClaimConfirm />;

  return null;
};

interface ClaimByManuallyProps {
  chain: string
}

export const ClaimByManually: FC<ClaimByManuallyProps>  = ({ chain }) => {
  return (
    <Provider chain={chain}>
      <Box>
        <Inner />
      </Box>
    </Provider>
  );
};
